import { render, staticRenderFns } from "./building_block.vue?vue&type=template&id=34eceb47&scoped=true&"
import script from "./building_block.vue?vue&type=script&lang=js&"
export * from "./building_block.vue?vue&type=script&lang=js&"
import style0 from "./building_block.vue?vue&type=style&index=0&id=34eceb47&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34eceb47",
  null
  
)

export default component.exports