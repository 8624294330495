import request from './request';

export const fetchContribution = async (url) => {
    try {
        let res = await request.get(url);
        if (res.errorCode === 200) {
            let payload = res.payload;
            let labels = [];
            let data = [];
            for (let i = 0; i < payload.length; i++) {
                labels.push(payload[i].Name);
                data.push(payload[i].ToNumber);
            }
            
            return {data, labels};
        }
        return {};
    }
    catch (err) {
        console.log(err);
        return [];
    }
}


export const fetchToNumber = async (url) => {
    try {
        let res = await request.get(url);
        if (res.errorCode === 200) {
            return res.payload;
        }
        return {};
    }
    catch (err) {
        console.log(err);
        return [];
    }
}