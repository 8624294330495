<template>
  <div class="introduces" :style="'left:'+left+'px;'+'top:'+top+'px'">
    <div class="title">{{ title }}</div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    left: {
      type: Number,
    },
    top: {
      type: Number,
    },
  },
}
</script>

<style scoped lang="scss">
.introduces {
  width: 320px;
  background-color: #000;
  position: absolute;
  left: 3000px;
  top: 1500px;
  border-radius: 15px;
  color: #fff;

  .title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    text-indent: 2rem;
    font-weight: bold;
    font-size: 18px;
  }

  .text {
    width: 280px;
    padding: 20px;
    word-wrap: break-word;
    word-break: normal;
    line-height: 27px;
    font-size: 14px;
  }
}
</style>