<template>
    <div class="Link">
      <div class="fn-top"></div>
      <div class="content-1">
        <div class="title">{{ marker.name }}</div>
        <div v-if="marker.info" class="slogan">{{ marker.info.slogan }}</div>
      </div>
      <div class="content-2">
        {{ marker.text }}
      </div>
      <div  v-if="marker.info" class="content-3">
        {{ marker.info.contributionTitle }}
      </div>
      <template  v-if="marker.info">
        <template v-if="marker.info.contributionUrl" >
            <div v-if="marker.info.qrlink" class="content-title">
                <div class="block">
                    <div class="number">1</div>
                    <div class="img">
                    <img
                        :src="marker.info.qrlink[0]">
                    </div>
                    <div class="nav" v-bind:class="{nav_open:nav_open=='失控蚂蚁'}" @click="getClass('失控蚂蚁')">失控蚂蚁</div>
                </div>
                <div class="block">
                    <div class="number">2</div>
                    <div class="img">
                    <img
                        :src="marker.info.qrlink[1]">
                    </div>
                    <div class="nav" v-bind:class="{nav_open:nav_open=='灿若星辰'}" @click="getClass('灿若星辰')">灿若星辰</div>
                </div>
                <div class="block">
                    <div class="number">3</div>
                    <div class="img">
                    <img
                        :src="marker.info.qrlink[2]">
                    </div>
                    <div class="nav" v-bind:class="{nav_open:nav_open=='萬人添象'}" @click="getClass('萬人添象')">萬人添象</div>
                </div>
            </div>
            <hori-bar ref="horiBar"></hori-bar>
        </template>
        <template v-else >
            <div class="content-4">
                <div v-for="(item, index) in marker.info.members" :key="'member_' + index">
                    <p>{{ item }}</p>
                </div>
            </div>
        </template>
        <div class="contact">
            <div class="title">联系方式</div>
            <div class="text">{{ marker.info.contactName }}：{{ marker.info.contactEmail }}</div>
        </div>
      </template>
    </div>
  </template>
  
  <script>
  import HoriBar from "@/views/Map/com/hori_bar";
  import { fetchContribution } from '../../../utils/general';
  // import Chart from "chart.js";
  
  export default {
    components:{
      HoriBar
    },
    props: {
        marker: {
            type: Object,
            required: true
        }
    },
    watch: {
        marker: function(_marker) {
            let that = this;
            if (_marker.info) {
              let url = _marker.info.contributionUrl;
              if (_marker.info.qrlink) {
                  url = url + that.nav_open;
              }
              (async function(){
                  let {data, labels} = await that.initData(url);
                  that.$refs.horiBar.updateChart(data, labels);
              })();
            }
        }
    },
    data(){
      return{
        nav_open: '失控蚂蚁'
      }
    },
    methods: {
      initData: async (url) => {
        let res = await fetchContribution(url);
        return res;
      },
      async getClass(val) {
        let that = this;
        this.nav_open = val;
        let url = this.marker.info.contributionUrl + val;
        let {data, labels} = await that.initData(url);
        that.$refs.horiBar.updateChart(data, labels);
      },
    },
    mounted() {
        let that = this;
        (async function(){
            let url = that.marker.info.contributionUrl;
            if (that.marker.info.qrlink) {
                url = url + that.nav_open;
            }
            let {data, labels} = await that.initData(url);
            that.$refs.horiBar.updateChart(data, labels);
        })();
    }
  }
  </script>
  
  <style scoped lang="scss">
  .Link{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .fn-top{
      width: 100%;
      height: 100px;
    }
    .content-1{
      width: 100%;
      // height: 21px;
      // background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
      margin-bottom: 20px;
      .title{
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
        margin-bottom: 10px;
      }
      .slogan{
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        text-align: center;
      }
    }
  
    .content-2{
      color: #fff;
      width: 80%;
      font-size: 15px;
      line-height: 27px;
      margin: 0 auto;
    }
  
    .content-3{
      width: 100%;
      height: 50px;
      color: #fff;
      line-height: 50px;
      border-top: 1px solid #979797;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-top: 30px;
    }
    .content-4{
      display: block;
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 40px;
      p {
        text-align: center;
        width: 100%;
        height: 20px;
        line-height: 20px;
        color: #fff;
        font-size: 16px;
      }
    }
  
    .contact{
      color: #A3A3A3;
      text-align: center;
      margin-top: 15px;
      user-select: none;
      .title{
        font-size: 15px;
        font-weight: bold;
      }
  
      .text{
        font-size: 13px;
      }
    }

    .content-title {
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;

        .block {
        width: 100px;
        height: 130px;
        position: relative;
        display: inline-block;
        margin-right: 17px;

        .number {
            color: #A3A3A3;
            font-size: 30px;
            position: absolute;
            left: -15px;
            top: -15px;
            font-weight: bold;
        }

        .img {
            width: 80px;
            height: 80px;
            margin: 0 auto;

            img {
            width: 100%;
            height: 100%;
            }
        }

        .nav {
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            width: 80px;
            height: 30px;
            line-height: 30px;
            border-radius: 20px;
            border: 1px solid #fff;
            cursor: pointer;
            user-select: none;
            margin: 0 auto;
            margin-top: 10px;
        }

        .nav_open {
            background-color: #3D75E4;
            border: none;
        }
        }
    }
  
  }
  </style>
  