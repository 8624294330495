<template>
  <div class="block" :style="pos" @mousemove="getStartClass()" @mouseout="notStartClass()" @dblclick="openUrl">
    
  </div>
</template>

<script>
export default {
  props: {
    pos: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      
    }
  },
  mounted() {

  },
  methods: {
    openUrl(){
      if (this.name=='3QLAB'){
        this.$router.push('/PutDisplay')
      }
    },
    getStartClass(){
      this.$parent.getStartClass(this.name, this.idx);
    },
    notStartClass(){
      this.$parent.notStartClass();
    }
  }
}
</script>

<style scoped lang="scss">
    .block {
        // background: black;
        position: absolute;
        cursor: pointer;
    }
</style>