<template>
  <div class="Map">
    <Header></Header>
    <div class="LookImage" ref="mapWindow" @mouseenter="Imagefocus" @mouseleave="Imageblur" :style="LookImage">
      <div class="hint-box" v-if="StartClass=='3QLAB'">双击建筑进入展厅</div>
      <div class="FullScreen" @click="openFullScreen"></div>
      <div class="close" @click="close"></div>
      <div
          ref="image"
          class="img"
          :style="imgStyle"
          @mousedown="handleMouseDown">
        <div v-for="(item,index) in markers" :key="index+'b'" class="fnImage"
             :style="'height:'+item.openimage.height+'px;width:'+item.openimage.width+'px;left:'+item.openimage.left+'px;'+'top:'+item.openimage.top+'px'">
          <img :src="item.openimage.url" v-if="item.openimage.url&&item.name==StartClass"/>
        </div>
        <building-block :pos="item.size" :name="item.name" :idx="index" v-for="(item,index) in markers" :key="index"/>
        <div v-for="(item,index) in markers" :key="index+'a'">
          <introduce :title="item.name" :text="item.text" :left="item.left" :top="item.top"
                     v-if="introduceView&&item.name==StartClass&&item.text&&LookImage"></introduce>
        </div>
      </div>
    </div>
    <div class="Content">
      <Link :marker=markers[curMarker] />
    </div>
    <div class="Footer">
      <div class="end">Copyright @ www.3qlab.com,All Rights Reserved.<a href="https://beian.miit.gov.cn/"
                                                                        target="_blank">浙ICP备19046295号-1</a></div>
    </div>
  </div>
</template>

<script>
import BuildingBlock from "./com/building_block";
import introduce from "@/views/Map/com/introduce";

import Header from "@/components/Header";
import Link from "@/views/Map/com/link";

import data from './data/data.json'


export default {
  data() {
    return {
      LookImage: '',
      StartClass: '3QLAB',
      curMarker: 0,
      currentImg: '',
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      },
      centerBuildingX: 0,
      centerBuildingY: 0,
      markers: data,
      introduceView: false,
    }
  },
  mounted() {
    this.init();
  },
  components: {
    Link,
    Header,
    BuildingBlock,
    introduce
  },
  computed: {
    imgStyle() {
      const {scale, deg, offsetX, offsetY, enableTransition} = this.transform;

      const style = {
        transform: `scale(${scale}) rotate(${deg}deg)`,
        transition: enableTransition ? 'transform .3s' : '',
        'margin-left': `${offsetX}px`,
        'margin-top': `${offsetY}px`
      };
      return style;
    },
  },
  beforeMount() {
    document.body.style.overflow = ''
  },
  beforeDestroy() {
    document.body.style.overflow = ''
  },
  destroyed() {
    document.body.style.overflow = ''
  },
  methods: {
    openFullScreen() {
      if (this.LookImage) {
        this.LookImage = ''
      } else {
        this.LookImage = 'width: 100%;height: 100%;left: 0;top: 0;margin-left: 0;margin-top: 0;'
      }
    },
    getStartClass(value, idx) {
      this.StartClass = value;
      this.curMarker = idx;
      if (this.StartClass == '3QLAB' && !this.LookImage) {
        this.introduceView = false
      } else {
        this.introduceView = true
      }
    },
    notStartClass() {
      this.introduceView = false
    },
    Imagefocus() {
      document.addEventListener('mousewheel', this._mouseWheelHandler, false);
    },
    Imageblur() {
      document.removeEventListener('mousewheel', this._mouseWheelHandler, false);
    },
    init() {
      setTimeout(() => {
        document.body.style.overflow = 'hidden'
        this.reset();
        this.getImage();
        this.transformPosition();
      })
    },
    transformPosition() {
      let markers_t = [];
      for (let i = 0; i < this.markers.length; i++) {
        let curMarkers = this.markers[i];
        curMarkers.size.width = curMarkers.size.width + 'px';
        curMarkers.size.height = curMarkers.size.height + 'px';
        curMarkers.size.left = this.centerBuildingX + curMarkers.size.left + 'px';
        curMarkers.size.top = this.centerBuildingY + curMarkers.size.top + 'px';
        markers_t.push(curMarkers);
      }
      this.markers = markers_t;
    },
    getImage() {
      let mapWidth = this.$refs.image.scrollWidth;
      let mapHeight = this.$refs.image.scrollHeight;
      this.centerBuildingX = mapWidth / 2 - 320;
      this.centerBuildingY = mapHeight / 2 - 110;

      this.transform.offsetX = -this.centerBuildingX + 280;
      this.transform.offsetY = -this.centerBuildingY + 200;
    },
    reset() {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      };
    },
    handleMouseDown(e) {
      if (this.loading || e.button !== 0) return;

      const {offsetX, offsetY, scale} = this.transform;
      const startX = e.pageX;
      const startY = e.pageY;

      this._dragHandler = this.rafThrottle(ev => {
        const originWidth = this.$refs.image.scrollWidth;
        const originHeight = this.$refs.image.scrollHeight;
        const boxLeft = this.$refs.mapWindow.getBoundingClientRect().left;
        const boxTop = this.$refs.mapWindow.getBoundingClientRect().top;
        const boxRight = this.$refs.mapWindow.getBoundingClientRect().right;
        const boxBottom = this.$refs.mapWindow.getBoundingClientRect().bottom;

        const centerX = boxLeft + offsetX + ev.pageX - startX + (originWidth / 2);
        const centerY = boxTop + offsetY + ev.pageY - startY + (originHeight / 2);

        if (centerX - originWidth * scale / 2 < boxLeft && centerX + originWidth * scale / 2 > boxRight && centerY - originHeight * scale / 2 < boxTop && centerY + originHeight * scale / 2 > boxBottom) {
          this.transform.offsetX = offsetX + ev.pageX - startX;
          this.transform.offsetY = offsetY + ev.pageY - startY;
        }

      });

      this.clearHandler = () => {
        window.removeEventListener('mousemove', this._dragHandler, false);
      }

      window.addEventListener('mousemove', this._dragHandler, false);
      window.addEventListener('mouseup', this.clearHandler, false);

      e.preventDefault();
    },
    rafThrottle(fn) {
      let locked = false;
      return function (...args) {
        if (locked) return;
        locked = true;
        window.requestAnimationFrame(() => {
          fn.apply(this, args);
          locked = false;
        });
      };
    },
    _mouseWheelHandler(e) {
      const delta = e.wheelDelta ? e.wheelDelta : -e.detail;

      if (delta > 0) {
        this.handleActions('zoomIn', {
          zoomRate: 0.015,
          enableTransition: false
        });
      } else {
        this.handleActions('zoomOut', {
          zoomRate: 0.015,
          enableTransition: false
        });
      }
    },
    handleActions(action, options = {}) {
      if (this.loading) return;
      const {zoomRate, rotateDeg, enableTransition} = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options
      };
      const {transform} = this;
      switch (action) {
        case 'zoomOut':
          if (transform.scale > 0.2) {
            let sumTol = parseFloat((transform.scale - zoomRate).toFixed(3));
            let targetScale = Math.max(sumTol, 0.65);
            const originWidth = this.$refs.image.scrollWidth;
            const originHeight = this.$refs.image.scrollHeight;
            const boxLeft = this.$refs.mapWindow.getBoundingClientRect().left;
            const boxTop = this.$refs.mapWindow.getBoundingClientRect().top;
            const boxRight = this.$refs.mapWindow.getBoundingClientRect().right;
            const boxBottom = this.$refs.mapWindow.getBoundingClientRect().bottom;
            const centerX = boxLeft + transform.offsetX + (originWidth / 2);
            const centerY = boxTop + transform.offsetY + (originHeight / 2);

            if (centerX - originWidth * targetScale / 2 >= boxLeft
                || centerY - originHeight * targetScale / 2 >= boxTop
                || centerX + originWidth * targetScale / 2 <= boxRight
                || centerY + originHeight * targetScale / 2 <= boxBottom) {
              return;
            }
            transform.scale = targetScale;
          }
          break;
        case 'zoomIn':
          if (transform.scale > 0.2) {
            let sumTols = parseFloat((transform.scale + zoomRate).toFixed(3))
            if (sumTols >= 1.25) {
              transform.scale = 1.25;
            } else {
              transform.scale = sumTols;
            }
          }

          break;
        case 'clocelise':
          transform.deg += rotateDeg;
          break;
        case 'anticlocelise':
          transform.deg -= rotateDeg;
          break;
      }

      transform.enableTransition = enableTransition;
    },
    close() {
      document.removeEventListener('mousewheel', this._mouseWheelHandler, false);
      document.body.style.overflow = ''
    },
  }
}
</script>

<style scoped lang="scss">
.Map {
  background-color: #000;
  width: 100%;
  height: 100%;

  .Footer {
    width: 100%;
    height: 40px;
    font-size: 14px;
    position: absolute;
    color: #A3A3A3;
    bottom: 0;
    left: 0;

    .end {
      text-align: center;

      a {
        text-decoration: none;
        color: #A3A3A3;
        outline: none;
      }
    }
  }
}

.LookImage {
  width: 800px;
  height: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -600px;
  margin-top: -300px;
  overflow: hidden;
  background-color: #000;
  border: 1px solid white;
  z-index: 9999;

  .FullScreen {
    width: 30px;
    height: 30px;
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-3-13/FullScreen.png");
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
  }


  .img {
    background-image: url('https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/demo_2.png');
    width: 7329px;
    height: 3262px;
    //box-shadow: inset 0 10px 16px 6px rgba(0, 0, 0, 1);
    //border-radius: 20px;

    @mixin findLink {
      position: absolute;
      cursor: pointer;
      .link-en {
        width: 100%;
        height: 100%;
      }
    }

    .link-1 {
      width: 140px;
      height: 340px;
      left: 1277px;
      top: 1450px;
      @include findLink;
    }

    .link-2 {
      width: 190px;
      height: 250px;
      left: 1677px;
      top: 1750px;
      @include findLink;
    }

    .link-3 {
      width: 270px;
      height: 250px;
      left: 2020px;
      top: 2090px;
      @include findLink;
    }

    .link-4 {
      width: 270px;
      height: 254px;
      left: 1884px;
      top: 1522px;
      @include findLink;
    }

    .link-5 {
      width: 270px;
      height: 250px;
      left: 2153px;
      top: 1219px;
      @include findLink;
    }

  }
}

.Content {
  width: 400px;
  height: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: 200px;
  margin-top: -300px;
  overflow: hidden;
  background-color: #000;
}

.fnImage {
  position: absolute;

  img {
    // width: 100%;
    height: 100%;
  }
}

.hint-box {
  width: 50%;
  position: absolute;
  z-index: 9999;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  line-height: 40px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0,0,0,0) 100%);;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}
</style>
