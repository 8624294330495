<template>
  <div class="chart-container">
    <canvas id="hori-bar"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  data() {
    return {
      chart: null, 
      chartData: {
        // labels: ['陈俊茹', '陈梓萱', '宋词', '孔维恺忻', '王宇飞', '陈俊茹1', '陈梓萱', '宋词', '孔维恺忻', '王宇飞', '陈俊茹', '陈梓萱', '宋词', '孔维恺忻', '王宇飞'],
        labels: [],
        datasets: [
          {
            backgroundColor: '#5779cd',
            // data: [5630, 3005, 1190, 400, 0, 5630, 3005, 1190, 400, 0, 5630, 3005, 1190, 400, 0],
            data: [],
            barThickness: 10,
          }
        ]
      },
      chartOptions: {
        indexAxis: 'y',
        // responsive: false,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              
            }
          }],
          xAxes: [{
            display: false,
            ticks: {
              suggestedMax: 7000, // 要设置的比最大值大一些，否则数字显示不全
            }
          }]
        },
        legend: {
          display: false
        },
        events: [],
        animation: {
          onComplete: function () {
              var chartInstance = this.chart,

              ctx = chartInstance.ctx;
              // 以下属于canvas的属性（font、fillStyle、textAlign...）
              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
              ctx.fillStyle = "white";
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function (dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                      var data = dataset.data[index];
                      ctx.fillText(data, bar._model.x + 20, bar._model.y + 6);
                  });
              });
          }
        }
      },
      barLength: 50
    }
  },
  mounted() {
    this.chart = new Chart(
      document.getElementById('hori-bar'),
      {
        type: 'horizontalBar',
        data: this.chartData,
        options: this.chartOptions
      }
    )
  },
  methods: {
    updateChart(data, labels) {
      this.chart.data.labels=[]
      this.chart.data.datasets[0].data=[]
      for (let i = 0; i < data.length; i++) {
        this.chart.data.labels.push(labels[i]);
        this.chart.data.datasets.forEach((dataset) => {
            dataset.data.push(data[i]);
        });
      }
      let maxData = Math.max.apply(null, data);
      let minData = Math.min.apply(null, data);
      this.chart.options.scales.xAxes[0].ticks.suggestedMax = Math.floor(1.2 * maxData);
      this.chart.options.scales.xAxes[0].ticks.suggestedMin = Math.floor(0.8 * minData); 
      this.chart.canvas.parentNode.style.height = this.barLength * data.length + 'px';
      this.chart.update();
    }
  }
}
</script>

<style scoped>
  .chart-container {
    width: 90%; 
    height: 400px; 
    margin: 0 auto; 
    position:relative
  }
</style>